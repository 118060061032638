:root {
    --primary: #D81E5B;
    --secondary: #8A4EFC;
    --light: #EEE;
    --light-alt: #61759b;
    --dark: #131A26;
    --dark-alt: #202B3E;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Fira Sans', sans-serif;
}

body {
    background-color: var(--dark-alt);
    color: var(--light);
}

.App {
    padding: 32px;
}

h1 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 32px;
}

h4 {
    font-size: 18px;
    color: var(--light-alt);
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 16px;
}

.todo {
    position: relative;
    background-color: var(--light-alt);
    padding: 16px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    transition: 0.5s;
    cursor: pointer;
    margin-bottom: 16px;
    transition: 0.5s;
}

.todo.is-complete {
    position: relative;
    background-color: var(--dark);
    padding: 16px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    transition: 0.5s;
    cursor: pointer;
    margin-bottom: 16px;
    transition: 0.5s;
}

.todo:hover {
    opacity: 0.8;
}

.todo .checkbox {
    width: 20px;
    height: 20px;
    margin-right: 16px;
    border-radius: 999px;
    background-color: var(--dark-alt);
    transition: 0.4s;
}

.todo.is-complete .checkbox {
    background-color: var(--primary);
    background-image: linear-gradient((to bottom, var(--primary), var(--secondary)));
}

.todo .text {
    font-size: 20px;
}

.todo.is-complete .text {
    text-decoration: line-through;
}

.todo .delete-todo {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);

    width: 24px;
    height: 24px;
    color: var(--light);
    background-color: #af1e2d;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.addPopup {
    position: fixed;
    bottom: 32px;
    right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 64px;
    height: 64px;
    border-radius: 999px;
    font-size: 35px;
    color: var(--light);
    background-image: linear-gradient(to bottom right, var(--primary), var(--secondary));
    cursor: pointer;

}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    max-width: 400px;
    background-color: var(--light);
    padding: 32px;
    border-radius: 16px;
    box-shadow: 0px 3px 24px var(--dark);
}

.closePopup {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    color: var(--light);
    border-radius: 50%;
}

.popup h3 {
    color: var(--dark);
    margin-bottom: 16px;
    font-weight: 400;
    text-transform: uppercase;
}

.add-todo-input {
    appearance: none;
    border: none;
    outline: none;
    background-color: #fff;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 1px 2px 24px rgba(0, 0, 0, 0.2);
    width: 100%;
    font-size: 20px;

}

.button {
    padding: 16px 32px;
    border-radius: 99px;
    background-image: linear-gradient(to right, var(--primary), var(--secondary));
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 16px;
    text-align: center;
    cursor: pointer;
}